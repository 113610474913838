import { getList } from '@/requests/api/get-list';
import {
  type Invoice,
  type InvoiceRecipient,
  type Tenant,
} from '@pigello/pigello-matrix';
import { chunkIds } from '../utils';
import type { TCustomList } from './types';

// Custom handler for Invoice
export async function handleCustomInvoiceNestedCase(
  listResponse: TCustomList<Invoice>
): Promise<TCustomList<Invoice>> {
  const recipientIds = listResponse.data.reduce<Set<string>>((acc, invoice) => {
    invoice.invoiceRecipients?.forEach((recipient) => {
      if (recipient.id) {
        acc.add(recipient.id);
      }
    });
    return acc;
  }, new Set());

  if (recipientIds.size === 0) return listResponse;

  const chunkedRecipientIds = chunkIds(recipientIds);
  const resolvedRecipientsPromises = await Promise.allSettled(
    chunkedRecipientIds.map((chunk) =>
      getList<InvoiceRecipient>({
        modelName: 'invoicerecipient',
        queryParams: {
          page: 1,
          pageSize: chunk.length,
          filters: {
            id: {
              __in: chunk.join(','),
            },
          },
        },
      })
    )
  );

  const resolvedRecipients: InvoiceRecipient[] = [];
  const failedRecipients: unknown[] = [];
  for (const promise of resolvedRecipientsPromises) {
    if (promise.status === 'fulfilled') {
      resolvedRecipients.push(...(promise.value.list ?? []));
    } else {
      failedRecipients.push(promise.reason);
    }
  }

  const tenantIds = new Set<string>();
  resolvedRecipients.forEach((recipient) => {
    if (recipient.tenant?.id) {
      tenantIds.add(recipient.tenant.id);
    }
  });

  const chunkedTenantIds = chunkIds(tenantIds);
  const resolvedTenantsPromises = await Promise.allSettled(
    chunkedTenantIds.map((chunk) =>
      getList<Tenant>({
        modelName: 'tenant',
        queryParams: {
          page: 1,
          pageSize: chunk.length,
          filters: {
            id: {
              __in: chunk.join(','),
            },
          },
          order: ['-createdAt'],
        },
      })
    )
  );

  const resolvedTenants: Tenant[] = [];
  const failedTenants: unknown[] = [];
  for (const promise of resolvedTenantsPromises) {
    if (promise.status === 'fulfilled') {
      resolvedTenants.push(...(promise.value.list ?? []));
    } else {
      failedTenants.push(promise.reason);
    }
  }

  // Map tenants to recipients
  const mappedRecipients = resolvedRecipients.map((recipient) => ({
    ...recipient,
    tenant:
      resolvedTenants.find((tenant) => tenant.id === recipient.tenant?.id) ||
      recipient.tenant,
  }));

  // Update invoices with enriched recipients
  const mappedListResponse: TCustomList<Invoice> = {
    data: listResponse.data.map((invoice) => ({
      ...invoice,
      invoiceRecipients: invoice.invoiceRecipients.map((recipient) => {
        const matchedRecipient = mappedRecipients.find(
          (r) => r.id === recipient.id
        );
        return matchedRecipient || recipient;
      }),
    })) as Invoice[],
  };

  return mappedListResponse;
}
